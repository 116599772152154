@import '../../../styles/main.scss';

.brand-ford {
    .fm-drawer-slot-component {
        h2 {
            font-size: 36px;
            line-height: 44px;
            margin-bottom: 48px;
            font-weight: 600;
            @include desktop {
                margin-bottom: 64px;
            }
        }

        h3 {
            font-size: 24px;
            line-height: 32px;
            font-weight: 600;
            margin-bottom: 16px;
            color: $fds-color-black;
        }

        .sign-into-dashboard-description {
            font-size: 16px;
            line-height: 24px;
            color: $fds-color-black;
            margin-bottom: 24px;
            @include desktop {
                margin-bottom: 32px;
            }
        }

        .auth-dashboard-sign-in {
            .fm-vehicle-selector__switch-vehicle {
                width: 320px;
                justify-content: space-between;
                border-radius: 8px;
                border: 1px solid $fm-text-light-grey;
                padding: 8px 16px;
                color: $fds-color-black;
            }
            .fm-vehicle-selector__menu-items-panel {
                width: 320px;
            }
            .fm-btn {
                margin-top: 24px;
            }
        }
        .line {
            margin: 48px 0;
            width: 100%;
            border: 1px solid $fm-text-light-grey;
            @include desktop {
                margin: 64px 0;
            }
        }

        .search-by-vehicle-description {
            font-size: 16px;
            line-height: 24px;
            color: $fds-color-black;
            margin-bottom: 24px;
            @include desktop {
                margin-bottom: 48px;
            }
        }
        .fm-tabs-container .fm-tabs__list {
            margin-bottom: 24px;
            .fm-tabs__tab {
                border-bottom: 2px solid $fm-text-light-grey;
                color: $darkgrey;
                &.active {
                    border-color: $fm-breadcrumb-blue;
                    border-width: 4px;
                    color: $fm-breadcrumb-blue;
                }
            }
        }
        .home-page-ymm-container {
            .pre-imagine-ymm-container-dropdown {
                padding-bottom: 48px;
                max-width: 320px;
                .fm-dropdown-container {
                    margin-top: 24px;
                    padding-right: 10px;
                    padding-bottom: 0;
                    .fm-dropdown-header {
                        color: $fm-text-grey;
                    }

                    .fm-dropdown {
                        color: $fds-color-black;
                        max-width: unset;
                        width: 100%;
                    }
                }
            }
        }
        .pre-imagine-ymm-vin-body {
            margin-bottom: 32px;
            .fm-input-container {
                margin-bottom: 32px;
                @include desktop {
                    margin-bottom: 48px;
                }
                .fm-input-header {
                    color: $fm-text-grey;
                }
                .fm-input {
                    color: $fds-color-black;
                }
            }
        }
        .find-vin-cta {
            font-size: 12px;
            line-height: 16px;
            color: $darkgrey;
            font-weight: 400;
        }
    }

    .floating-panel__wrapper,
    .floating-panel__pointer {
        z-index: 9999;
    }
}
