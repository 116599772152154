.brand-ford {
    .fm-tabbed-accordions-container {
        margin: 0 56px 96px;
        @media (max-width: 991px) {
            margin: 0 0 64px;
        }
        .fm-tabbed-accordions-title {
            font-size: 40px;
            font-weight: 400;
            line-height: 48px;
            text-align: left;
            margin: 0 0 32px;
            @media (max-width: 991px) {
                margin: 0 16px 24px;
            }
        }

        .fm-tabbed-accordions {
            .fm-tabs-container {
                .fm-tabs__list::-webkit-scrollbar {
                    display: none;
                }
                .fm-tabs__list {
                    overflow-x: auto;
                    white-space: nowrap;
                    display: flex;
                    height: 32px;
                    gap: 0px;

                    @media (max-width: 991px) {
                        margin: 0 16px 24px;
                    }

                    .fm-tabs__tab {
                        padding-right: 32px;
                        font-size: 20px;
                        line-height: 28px;
                        text-align: left;
                        color: $fm-placeholder-grey;
                        white-space: nowrap;
                        overflow: visible;
                        height: 32px;
                        @media (max-width: 991px) {
                            font-size: 16px;
                        }
                    }
                }
            }
            .fm-accordion {
                padding: 0;
            }
        }
    }
}
