.brand-ford {
    .fm-signin-sbv-wrapper {
        background-color: $fds-text;
        padding: 48px 16px;
        @include desktop {
            padding: 48px 56px;
        }
        .fm-signin-sbv-content {
            display: flex;
            flex-direction: column;
            color: $fds-color-white;
            gap: 16px;
            padding-bottom: 24px;
            @include desktop {
                padding-bottom: 32px;
            }
            h2 {
                font-family: $f1-font-medium;
                margin-bottom: 0;
                line-height: 32px;
                font-size: 24px;
            }
            p {
                font-family: $f1-font-regular;
                line-height: 24px;
                font-size: 16px;
            }

            .disclaimer__tooltip-symbol {
                border-color: $fds-color-white;
                color: $fds-color-white;
            }
        }
        .fm-signin-sbv-buttons {
            display: flex;
            flex-direction: column;
            gap: 16px;
            @include desktop {
                flex-direction: unset;
            }
        }
    }
}
