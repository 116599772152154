.brand-ford {
    .fm-masthead {
        position: relative;
        width: 100%;
        height: 456px;
        overflow: hidden;
        @media (max-width: 991px) {
            height: 550px;
        }
        @media (max-width: 480px) {
            height: 656px;
        }
        .fm-masthead-image {
            position: relative;
            width: 100%;
            object-fit: none;
            object-position: 0% 70%;
            zoom: 110%;

            height: 456px;
            z-index: 0;
            @media (max-width: 991px) {
                height: 550px;
                object-position: 63% 35%;
                zoom: 100%;
            }
            @media (max-width: 480px) {
                height: 656px;
                object-position: 63% 10%;
                zoom: 100%;
            }
        }
        .fm-masthead-overlay {
            z-index: 3;
            background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.6),
                rgba(0, 0, 0, 0)
            );
            position: absolute;
            top: 0px;
            height: 100%;
            width: 100%;
            left: 0;
            @media (max-width: 991px) {
                height: 100%;
                width: 100%;
                background: linear-gradient(
                    180deg,
                    rgba(0, 0, 0, 1),
                    rgba(0, 0, 0, 0)
                );
            }
            .fm-masthead-content {
                padding: 86px 56px 0px;
                .fm-masthead-search-title {
                    color: $fds-color-white;
                    font-size: 48px;
                    font-weight: 400;
                    line-height: 56px;
                    text-align: left;
                    margin-bottom: 48px;
                }
                @media (max-width: 991px) {
                    padding: 40px 16px 0px;
                    .fm-masthead-search-title {
                        font-size: 36px;
                        line-height: 44px;
                        margin-bottom: 40px;
                    }
                }
                .preimagine-search-bar {
                    margin-bottom: 32px;
                    @media (max-width: 991px) {
                        margin-bottom: 24px;
                    }
                    .search-form {
                        margin-top: 0;
                        max-width: 656px;
                        width: 100%;
                        .autocomplete__input::placeholder {
                            color: $fm-placeholder-grey;
                            font-size: 12px;
                            line-height: 16px;
                            font-weight: 400;
                        }
                    }
                }
            }
        }
    }
}
