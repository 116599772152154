.brand-ford {
    .trending-topics-container {
        display: flex;
        width: 100%;
        max-width: 656px;
        flex-wrap: wrap;
        .trending-topic-button {
            margin: 0 16px 16px 0;
        }
    }
}
