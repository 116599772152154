.brand-ford {
    .fm-links-container {
        margin: 96px 0;
        padding: 0 56px;
        font-family: $f1-font-regular;
        color: $fm-text-grey;

        .fm-links-title {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 32px;
        }

        .fm-links-categories {
            display: grid;
            grid-auto-flow: column;
            grid-auto-columns: minmax(0, 25%);
            gap: 16px;

            .fm-links-category {
                padding: 24px;
                border-radius: 8px;
                border: 1px solid $grey;

                &__title {
                    font-size: 24px;
                    line-height: 32px;
                    font-family: $f1-font-semibold;
                    margin-bottom: 32px;
                }

                &__items {
                    display: flex;
                    flex-direction: column;
                    gap: 24px;

                    &__item {
                        color: $fm-breadcrumb-blue;
                        font-size: 14px;
                        line-height: 20px;
                        font-family: $f1-font-semibold;
                    }
                }
            }
        }
    }

    .fm-links-list-mobile {
        .fm-accordion {
            font-family: $f1-font-regular;
            color: $fm-text-grey;

            .fm-accordion__title {
                font-size: 32px;
                line-height: 40px;
            }

            .fm-accordion__panels {
                gap: 16px;

                .fm-accordion-panel__details {
                    border-radius: 8px;
                    border: 1px solid $grey;
                    overflow: hidden;

                    .fm-accordion-panel__header {
                        height: 80px;
                        border: none;

                        .fm-accordion-panel__header-title {
                            color: $fm-text-grey;
                            font-size: 24px;
                            line-height: 32px;
                            font-family: $f1-font-semibold;
                        }

                        &::after {
                            color: $fm-text-grey;
                        }
                    }

                    .fm-links-category {
                        &__items {
                            display: flex;
                            flex-direction: column;
                            gap: 24px;

                            &__item {
                                color: $fm-breadcrumb-blue;
                                font-size: 14px;
                                line-height: 20px;
                                font-family: $f1-font-semibold;
                            }
                        }
                    }
                }
            }
        }
    }

    .fm-links-list-placeholder {
        min-height: 440px;

        @include fds-large {
            min-height: 400px;
        }

        @include fds-medium {
            min-height: 450px;
        }
    }
}
