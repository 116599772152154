@import '../../styles/main.scss';
.brand-ford {
    .shared-view.smash-home {
        background: $fds-color-white;
        color: $fm-text-grey;
        .fm-home-featured-articles-carousel-header {
            margin: 64px 16px 0 16px;
            @include desktop {
                margin: 96px 16px 0 56px;
            }
            .fm-home-featured-articles-carousel__title {
                font-size: 40px;
                line-height: 48px;
                margin-bottom: 0;
            }
        }

        .fm-carousel__container {
            min-height: 627px;
            @include tablet {
                min-height: 696px;
            }
            @include desktop {
                min-height: 637px;
            }
            &.unimode {
                padding-right: 0px;
                @include desktop {
                    padding-right: 56px;
                }
            }

            .fm-card__content--container .fm-card__title {
                font-size: 32px;
                line-height: 40px;
                margin-bottom: 16px;
            }

            .fm-carousel__wrapper {
                padding: 24px 0 0;
                @include desktop {
                    padding: 32px 0 24px;
                }
            }

            .fm-card__container .fm-card__button--container {
                margin: 0 16px;
            }
        }
    }
}
