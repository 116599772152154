.brand-ford {
    .fm-escape-hatch-container {
        margin: 0 56px 48px 56px;
        padding: 48px 32px;
        text-align: left;
        border: 1px solid
            var(--color-stroke-on-light-subtle-dividers, rgba(178, 178, 178, 1));
        background: var(--color-fill-onLight-subtlest, rgba(250, 250, 250, 1));
        border-radius: 8px;
        @media (max-width: 991px) {
            padding: 24px;
            margin: 0 16px 32px;
        }
        .fm-escape-hatch-heading {
            font-size: 32px;
            line-height: 40px;
            margin-bottom: 24px;
        }
        .fm-escape-hatch-subheading {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 24px;
        }

        .fm-escape-hatch-buttons-container {
            display: flex;
            gap: 32px;
            @media (max-width: 991px) {
                flex-direction: column;
                gap: 16px;
            }
            .fm-escape-hatch-button {
                display: flex;
                align-items: center;
                .fm-btn.fm-btn--secondary.fm-btn__light {
                    border-color: $fm-breadcrumb-blue;
                    color: $fm-breadcrumb-blue;
                    .fm-escape-hatch-button-img {
                        margin-left: 8px;
                    }
                }
            }
        }
    }
}
