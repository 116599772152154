.brand-ford {
    .fm-overlay-banner {
        position: relative;
        height: 450px;
        margin: 56px 0;
        padding: 0 16px;
        @include desktop {
            height: 400px;
            margin: 96px 0;
            padding: 0 56px;
        }

        &__img {
            width: 100%;
            object-fit: cover;
            border-radius: 16px;
            position: relative;
            overflow: hidden;
            height: 450px;
            @include desktop {
                height: 400px;
            }
        }

        &__gradient {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 16px;
            z-index: 1;
            pointer-events: none;
            background: linear-gradient(
                to top,
                rgba(0, 0, 0, 0.8),
                rgba(0, 0, 0, 0.6) 50%,
                rgba(0, 0, 0, 0) 70%
            );
            width: calc(100% - 32px);
            margin: 0 16px;
            @include desktop {
                background: radial-gradient(
                    circle at bottom left,
                    rgba(0, 0, 0, 0.8),
                    rgba(0, 0, 0, 0.6) 30%,
                    rgba(0, 0, 0, 0) 50%
                );
                width: calc(100% - 112px);
                margin: 0 56px;
            }
        }

        &__content {
            position: absolute;
            bottom: 0;
            font-family: $f1-font-regular;
            color: $fm-moderate-on-dark;
            left: 16px;
            z-index: 2;
            width: calc(100% - 32px);
            padding: 0 16px 24px;

            @include desktop {
                width: 40%;
                padding: 40px;
                left: 56px;
            }

            &__title {
                font-family: $f1-font-semibold;
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 8px;
                @include desktop {
                    margin-bottom: 16px;
                }
            }

            &__description {
                font-size: 16px;
                line-height: 24px;
            }

            &__buttons {
                display: flex;
                gap: 16px;
                margin-top: 32px;
                flex-direction: column;
                @include desktop {
                    margin-top: 24px;
                    flex-direction: row;
                }

                .fm-btn--secondary {
                    color: $fm-moderate-on-dark;
                    border-color: $fm-moderate-on-dark;
                    &:hover {
                        color: $fm-skyview;
                        border-color: $fm-moderate-on-dark;
                    }
                }
            }
            .disclaimer__tooltip-symbol {
                color: $fm-moderate-on-dark;
                border-color: $fm-moderate-on-dark;
            }
        }
    }
}
